export const SERVER_API_ENDPOINT = `${process.env.REACT_APP_API}/api/v1`;
export const SERVER_API_SOCKET = process.env.REACT_APP_API;
export const UPBOND_ENV = process.env.REACT_APP_UPBOND_BUILD_EN || 'development';
export const PAYMENT_PUBLIC_KEY = process.env.REACT_APP_PAYMENT_PUBLIC_KEY || '';
export const OPENSEA_LINK = 'https://testnets.opensea.io/assets/mumbai/';

// 組み換え3Dモデルビューの表示制御
export const MODEL_VIEWER_SHOW_AXIS = process.env.REACT_APP_MODEL_VIEWER_SHOW_AXIS === 'true' ? true : false;
export const MODEL_VIEWER_SHOW_BONES = process.env.REACT_APP_MODEL_VIEWER_SHOW_BONES === 'true' ? true : false;


export type BuiltinAnimation = {
    id: number,
    name: string,
    path: string
  }

export const BUILTIN_ANIMATIONS: BuiltinAnimation[] = [
    // {
    //     id: 1,
    //     name: 'モーション1',
    //     path: `/animations/20231015/${encodeURI('Standing Idle.fbx')}`
    // },
    {
        id: 1,
        name: 'モーション1',
        path: `/animations/20240130/${encodeURI('bj01.fbx')}`
    },
    {
        id: 2,
        name: 'モーション2',
        path: `/animations/20231015/${encodeURI('Defeat_mod_v2.fbx')}`
    },
    // {
    //     id: 3,
    //     name: 'モーション3',
    //     path: `/animations/20231015/${encodeURI('Boxing.fbx')}`
    // },
    {
        id: 3,
        name: 'モーション3',
        path: `/animations/20231015/${encodeURI('Punching Bag.fbx')}`
    },
    // {
    //     id: 4,
    //     name: 'モーション4',
    //     path: `/animations/20231015/${encodeURI('Breakdance Ready.fbx')}`
    // },
    {
        id: 4,
        name: 'モーション4',
        path: `/animations/20240130/${encodeURI('ac17.fbx')}`
    },
    {
        id: 5,
        name: 'モーション5',
        path: `/animations/20231015/${encodeURI('nft_slpy_sylphy_walk_v004_v2.fbx')}`
    },
    {
        id: 6,
        name: 'モーション6',
        path: `/animations/20231108/${encodeURI('nft_slpy_sylphy_c01_v015.fbx')}`
    },
    {
        id: 7,
        name: 'Tポーズ',
        path: `/animations/${encodeURI('dta_empty_anim.fbx')}`
    },
    // {
    //     'id': 1,
    //     'name': 'Catwalk Idle To Twist R.fbx',
    //     'path': `/animations/${encodeURI('Catwalk Idle To Twist R.fbx')}`
    // },
    // {
    //     'id': 2,
    //     'name': 'Catwalk Walk Forward HighKnees.fbx',
    //     'path': `/animations/${encodeURI('Catwalk Walk Forward HighKnees.fbx')}`
    // },
    // {
    //     'id': 3,
    //     'name': 'Female Dance Pose.fbx',
    //     'path': `/animations/${encodeURI('Female Dance Pose.fbx')}`
    // },
    // {
    //     'id': 4,
    //     'name': 'Female Standing Pose.fbx',
    //     'path': `/animations/${encodeURI('Female Standing Pose.fbx')}`
    // },
    // {
    //     'id': 5,
    //     'name': 'Martelo 2.fbx',
    //     'path': `/animations/${encodeURI('Martelo 2.fbx')}`
    // },
    // {
    //     'id': 6,
    //     'name': 'Orc Idle.fbx',
    //     'path': `/animations/${encodeURI('Orc Idle.fbx')}`
    // },
]