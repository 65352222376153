import { NftImagesRes, NftWeaponRes } from "./common";

export enum NFT_TYPE {
  FIRST_NFT = 'first_nft',
  SECOND_NFT = 'second_nft',
}

export enum NFT_IMAGE_TYPE {
  PREVIEW = 'preview',
  MODEL = 'model',
  PREVIEW_2D = 'preview2D'

}



export interface NftDataRes {
  id: number;
  name: string;
  description: string;
  nftType: string;
  nftId: number;
  countLike: number | null;
  countView: number | null;
  status: string;
  creatorAddress: string;
  creatorId: number;
  nftTitle: string;
  ownerName: string;
  imagePreview2DId: number | null;
  previewImg: string;
  preview2DObjectKey: string;
  preview2DStatus: string;
  remainAmount: string | number;
  isLiked?: boolean;
  firstNftId: number;
  secondNftId: number;
  firstNftType?: number | string | null;
  title?: string;
  images?: NftImagesRes[];
  bodyPreview3DObjectKey?: string;
  weapon?: NftWeaponRes[];
  quantity?: string | number;
  preset?: NftWeaponRes;
  type?: string;
  characterId?: number;
  secondType?: "COMBINE" | "USER_CUSTOMIZE";
  updatedDate?: string;
  is2D?: boolean;
}

export interface OwnedNft {
  nftId: number;
  totalRemainAmount: number;
}

export interface OwnedNftRes {
  items: Array<OwnedNft>;
  totalCount: number;
}

interface Owner {
  ownerName: string;
  presignOwnerAvatar: {
    path: string;
    presignUrl: string;
  }
}

export interface NftDataResDetail {
  id: number | string;
  firstNftId: number | string | null;
  secondNftId: number | string | null;
  title: string;
  description: string | null;
  nftId: number | string;
  positionId: number | null;
  totalLike: number | string;
  totalView: number | string;
  bodyNftImageStatus: null;
  bodyNftImageObjectKey: null;
  bodyNftImageObjectKeyFileName: null;
  bodyNftId: number | string | null;
  bodyTitle: string | null;
  bodyDescription: string | null;
  presetId: number | string | null;
  weaponIds: number | string | null;
  presetNftId: number | string | null;
  weaponNftIds: number | string | null;
  remainAmount: number | string;
  isLiked: boolean;
  images: NftImagesRes[];
  isOwner: boolean;
  body?: {
    bodyTitle: string;
    bodyDescription: string;
    bodyNftImageObjectKey: string;
    bodyNftImageStatus: string;
    bodyNftImageObjectKeyFileName: string;
    bodyNftImageObjectKey3D: string;
    ownersBody: any;
    countOwner: number;
  };
  propertiesSecondNft: Array<{ property: string, value: string }> | null,
  preset?: any;
  weapons: any;
  characterName: string;
  ownerName: string;
  owner: Owner | null;
  secondNftStatus: string | null;
  secondType: string | null;
  reason: string | null
  quantityNft: string | number;
  ownersWeapon?: any;
  is2D?: boolean;
  originSimpleNft: any;
};
