import { notification } from 'antd';
import { BasicParam, First2ndNftBuyFilterRequest } from 'models/2nd-nft';
import { ListResponse } from 'models/common';
import {
  FirstNftBuy,
  FirstNftBuyFilterRequest,
  FirstNftDetailCombinedRequest,
  FirstNftBuyIllustrationFilterRequest,
} from 'models/first-nft';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';

const Nft1stServices = {
  getListBuy1stNft: async (params: FirstNftBuyFilterRequest) => {
    try {
      // 購入済のものを取得したい時は「isOwned」をtrueにする。そうでない場合、「購入可能な」リストを取得する
      const res: ListResponse<FirstNftBuy> = await ApiUtils.fetch(API.nftList1stBuy, params);
      return res;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    }
  },
  getListBuy1stIllustrationNft: async (params: FirstNftBuyIllustrationFilterRequest) => {
    try {
      const res: ListResponse<FirstNftBuy> = await ApiUtils.fetch(
        API.nftList1stIllustrationBuy,
        params
      );
      return res;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    }
  },
  getList2ndtNft: async (params: First2ndNftBuyFilterRequest) => {
    // 2次NFTを取得しようとしている
    try {
      console.log('API.nftList1stBuy: ' + API.nftList1stBuy)
      const res: ListResponse<FirstNftBuy> = await ApiUtils.fetch(API.nftList1stBuy, params);
      return res;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    }
  },
  getDetailBuy1stNft: async (id: number) => {
    try {
      const res: FirstNftBuy = await ApiUtils.fetch(
        API.nftDetail1stBuy.replace('{{id}}', id.toString())
      );
      return res;
    } catch (error) {
      notification.error({
        message: 'Error',
        description:
          "The nft that's looking for does not exist. Please try again or contact to admin!",
        duration: 4,
      });
    }
  },
  getDetailCombined: async (params: FirstNftDetailCombinedRequest) => {
    const res: any = await ApiUtils.fetch(API.getDetailCombined, params);
    return res;
  },
  getListPosition: async (params: BasicParam) => {
    const res: any = await ApiUtils.fetch(API.getDetailCombined, params);
    return res;
  },
};

export default Nft1stServices;
