import { notification } from 'antd';
import {
  List2ndNftRequest,
  MintRequest,
  Presign2ndNftFileParams,
  PresignRequest,
  PresignSimple2ndNftFileParams,
  Upload2ndNftParams,
  UploadSimple2ndNftParams,
} from 'models/2nd-nft';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';

const Nft2ndServices = {
  getList2ndNft: async (data: List2ndNftRequest) => {
    try {
      const res: any = await ApiUtils.fetch(API.nftList2nd, data);
      return res;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    }
  },
  mint2ndNft: async (data: MintRequest) => {
    try {
      const res: any = await ApiUtils.post(API.nftList2nd, data);
      return res;
    } catch (error) {
      notification.error({ message: 'Error', description: error?.message, duration: 4 });
    }
  },
  get2ndCustom: async (data: MintRequest) => {
    try {
      const res: any = await ApiUtils.fetch(API.getDetailCombined, data);
      return [res, null];
    } catch (error) {
      return [null, error];
    }
  },
  preSign2dPreview: async (data: PresignRequest) => {
    try {
      const res: any = await ApiUtils.post(API.presignImage, data);
      return res;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Upload image failed', duration: 4 });
    }
  },

  upload2ndNft: async (data: Upload2ndNftParams) => {
    try {
      const res: any = await ApiUtils.post(API.upload2ndNft, data);
      return res;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message || 'Something went wrong!',
        duration: 4,
      });
    }
  },

  presign2ndNftFile: async (
    data: Presign2ndNftFileParams,
    errMessage = 'Something went wrong!'
  ) => {
    try {
      const res: any = await ApiUtils.post(API.presign2ndNftFile, data);
      return res;
    } catch (error) {
      notification.error({ message: 'Error', description: errMessage, duration: 4 });
    }
  },

  uploadSimple2ndNft: async (data: Partial<UploadSimple2ndNftParams>) => {
    try {
      const res: any = await ApiUtils.post(API.uploadSimple2ndNft, data);
      return res;
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message || 'Something went wrong!',
        duration: 4,
      });
    }
  },

  // このSimpleは何を意味するのか？
  presignSimple2ndNftFile: async (
    data: PresignSimple2ndNftFileParams,
    errMessage = 'Something went wrong!'
  ) => {
    try {
      const res: any = await ApiUtils.post(API.presignSimple2ndNftFile, data);
      return res;
    } catch (error) {
      notification.error({ message: 'Error', description: errMessage, duration: 4 });
    }
  },
};

export default Nft2ndServices;
