import { Col, notification, Row } from 'antd';
import { NftDataResDetail, NFT_IMAGE_TYPE } from 'models/nft';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'utils/api/api-route';
import ApiUtils from 'utils/api/api.utils';
import ModelCardEquipment from './modal-card-equipment';
import ModelCard2nd from './model-card-2nd';
import useGetImageNft from 'hooks/useGetImageNft';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { UserProfile } from 'models/common';
import LoadingUpbond from 'components/loading-upbond';

type DetailModalProps = {
  id: string | number;
  profileUser: UserProfile | undefined;
};

export interface ItemEquipment {
  preView3d: string | undefined;
  title: string;
  description: string;
  owners: any;
  cardName: string;
  type: string;
  countOwner: number;
  is2d?: boolean;
}

const Detail2stApproveRejectModal: React.FC<DetailModalProps> = ({ id, profileUser }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<NftDataResDetail | null>({} as NftDataResDetail);
  const getPreview3dPresetNft = useGetImageNft(data?.preset?.images, NFT_IMAGE_TYPE.PREVIEW);
  const [loading, setLoading] = useState(false);

  const getDetail = useCallback(async () => {
    setLoading(true);
    try {
      const response: NftDataResDetail = await ApiUtils.fetch(`${API.nft}/${id}`);
      setData(response);
    } catch (error) {
      console.error(`Error detail 2st nft`, JSON.stringify(error));
      notification.error({ message: 'Error', description: 'Something went wrong!', duration: 4 });
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id, getDetail]);

  const getListEquipment = useMemo(() => {
    let listEquipment: Array<ItemEquipment> = [];
    if (data?.body?.bodyNftImageObjectKey3D) {
      listEquipment.push({
        preView3d: data?.body?.bodyNftImageObjectKey3D,
        title: data?.body?.bodyTitle,
        description: data?.body?.bodyDescription,
        owners: data?.body?.ownersBody,
        countOwner: data?.body?.countOwner,
        cardName: t('MyPage.detail2ndRequest.body'),
        type: 'body',
      });
    }
    if (!_.isEmpty(data?.preset)) {
      listEquipment.push({
        preView3d: getPreview3dPresetNft,
        title: data?.preset?.title,
        description: data?.preset?.description,
        owners: data?.preset?.ownersPresets,
        countOwner: data?.preset?.countOwner,
        cardName: t('MyPage.detail2ndRequest.preset'),
        type: 'presets',
      });
    }

    if (data?.weapons && data?.weapons.length > 0) {
      const arrConvert = data?.weapons.map((item) => {
        return {
          preView3d:
            item?.images &&
            item?.images.find((item) => item.type === NFT_IMAGE_TYPE.PREVIEW_2D).objectKey,
          title: item?.title,
          description: item?.description,
          owners: item?.ownersWeapon,
          countOwner: item?.countOwner,
          cardName: t('MyPage.detail2ndRequest.weapon'),
          type: 'weapon',
        };
      });
      listEquipment = listEquipment.concat(arrConvert);
    }
    if (data?.originSimpleNft && data?.originSimpleNft?.ownersfirstNftSimpleOrigin?.length > 0) {
      listEquipment.push({
        preView3d: data?.originSimpleNft?.firstNftSimpleOriginImageObjectKey,
        title: data?.originSimpleNft?.firstNftSimpleOriginTitle,
        description: data?.originSimpleNft?.firstNftSimpleOriginDescription,
        owners: data?.originSimpleNft?.ownersfirstNftSimpleOrigin,
        countOwner: data?.originSimpleNft?.countOwner,
        cardName:
          data?.originSimpleNft?.firstNftSimpleOriginType === 'body'
            ? t('MyPage.detail2ndRequest.body')
            : data?.originSimpleNft?.firstNftSimpleOriginType === 'preset'
            ? t('MyPage.detail2ndRequest.preset')
            : t('MyPage.detail2ndRequest.weapon'),
        type: 'weapon',
        is2d: true,
      });
    }

    return listEquipment;
  }, [
    data?.body?.bodyDescription,
    data?.body?.bodyTitle,
    data?.preset,
    data?.weapons,
    data?.body?.bodyNftImageObjectKey3D,
    data?.body?.ownersBody,
    getPreview3dPresetNft,
    data?.body?.countOwner,
    data?.originSimpleNft,
    t,
  ]);

  return (
    <>
      {loading ? (
        <div className="loadingModal">
          <LoadingUpbond />
        </div>
      ) : (
        <div style={{ padding: isMobile ? '0 1rem' : '0 3rem' }}>
          <Row>
            <ModelCard2nd data={data} profileUser={profileUser} />
          </Row>
          <Row gutter={[30, 16]} className="mb-10">
            {getListEquipment.map((item, index) => (
              <Col md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} key={index}>
                <ModelCardEquipment data={item} />
              </Col>
            ))}
          </Row>
          {data?.reason && (
            <Row
              gutter={[24, 8]}
              style={{
                margin: '3rem 0',
                padding: '1.2rem 0',
                borderRadius: '0.8rem',
                backgroundColor: '#fff1f1',
              }}
            >
              <Col> {t('MyPage.detail2ndRequest.reason')}</Col>
              <Col>
                <b>{data?.reason}</b>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default Detail2stApproveRejectModal;
